import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import "./App.css";
import PrivacyPolicy from "./PrivacyPolicy";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <div className="App">
              <header className="App-header">
                <img
                  src="/NimbleShortLet.png"
                  className="App-logo"
                  alt="logo"
                />
                Under construction. Check back later
                <Link to="/privacy-policy" className="link">Privacy Policy</Link>
              </header>
            </div>
          }
        />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
